jQuery(document).ready(function ($) {
  // Menu settings
  $('#menuToggle, .menu-close').on('click', function () {
    $('#menuToggle').toggleClass('active')
    $('body').toggleClass('body-push-toleft')
    $('#theMenu').toggleClass('menu-open')
  })
})
const API_URL = '/api/mainnet-data'
function numberWithCommas (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
function formatNumber (number, denom) {
  if (typeof number === 'string') return number
  return `${numberWithCommas(number.toFixed(2))} ${denom}`
}
async function main () {
  const apiData = await fetch(API_URL).then(data => data.json())
  // ODIN data
  document.querySelector('#odin-commission').innerHTML = formatNumber(apiData.odin.commission, '%')
  document.querySelector('#odin-amount').innerHTML = formatNumber(apiData.odin.totalStakeInUsd, '$')
  document.querySelector('#odin-self-stake').innerHTML = formatNumber(apiData.odin.selfBondedInToken, 'odin')
  document.querySelector('#odin-power').innerHTML = formatNumber(apiData.odin.totalStakeInToken, 'odin')
}
main()
